const ERROR_TYPE = "error";
export const DEFAULT_TIMEOUT = 15000;
/**
 * Take a Requester function which deals with tagged requests and responses,
 * and return a Requester which deals only with the inner data types, also
 * with the optional Transformer applied to further modify the input and output.
 */
export const requesterTransformer = (requester, tag, transform = identityTransformer) => async (req, timeout) => {
    const input = { type: tag, data: transform.input(req) };
    const response = await requester(input, timeout);
    const output = transform.output(response.data);
    return output;
};
const identity = (x) => x;
const identityTransformer = {
    input: identity,
    output: identity,
};
export const catchError = (res) => {
    return res.type === ERROR_TYPE ? Promise.reject(res) : Promise.resolve(res);
};
export const promiseTimeout = (promise, tag, ms) => {
    let id;
    const timeout = new Promise((_, reject) => {
        id = setTimeout(() => {
            clearTimeout(id);
            reject(new Error(`Timed out in ${ms}ms: ${tag}`));
        }, ms);
    });
    return new Promise((res, rej) => {
        Promise.race([promise, timeout])
            .then((a) => {
            clearTimeout(id);
            return res(a);
        })
            .catch((e) => {
            return rej(e);
        });
    });
};
