/**
 * Defines AppWebsocket, an easy-to-use websocket implementation of the
 * Conductor API for apps
 *
 *    const client = AppWebsocket.connect(
 *      'ws://localhost:9000',
 *      signal => console.log('got a signal:', signal)
 *    )
 *
 *    client.callZome({...})  // TODO: show what's in here
 *      .then(() => {
 *        console.log('DNA successfully installed')
 *      })
 *      .catch(err => {
 *        console.error('problem installing DNA:', err)
 *      })
 */
import { decode, encode } from "@msgpack/msgpack";
import { getLauncherEnvironment } from "../../environments/launcher.js";
import { WsClient } from "../client.js";
import { catchError, DEFAULT_TIMEOUT, promiseTimeout, requesterTransformer, } from "../common.js";
export class AppWebsocket {
    client;
    defaultTimeout;
    overrideInstalledAppId;
    constructor(client, defaultTimeout, overrideInstalledAppId) {
        this.client = client;
        this.defaultTimeout =
            defaultTimeout === undefined ? DEFAULT_TIMEOUT : defaultTimeout;
        this.overrideInstalledAppId = overrideInstalledAppId;
    }
    static async connect(url, defaultTimeout, signalCb) {
        // Check if we are in the launcher's environment, and if so, redirect the url to connect to
        const env = await getLauncherEnvironment();
        if (env) {
            url = `ws://localhost:${env.APP_INTERFACE_PORT}`;
        }
        const wsClient = await WsClient.connect(url, signalCb);
        return new AppWebsocket(wsClient, defaultTimeout, env ? env.INSTALLED_APP_ID : undefined);
    }
    _requester = (tag, transformer) => requesterTransformer((req, timeout) => promiseTimeout(this.client.request(req), tag, timeout || this.defaultTimeout).then(catchError), tag, transformer);
    appInfo = this._requester("app_info", appInfoTransform(this));
    callZome = this._requester("zome_call", callZomeTransform);
}
const callZomeTransform = {
    input: (req) => {
        return {
            ...req,
            payload: encode(req.payload),
        };
    },
    output: (res) => {
        return decode(res);
    },
};
const appInfoTransform = (appWs) => ({
    input: (req) => {
        if (appWs.overrideInstalledAppId) {
            return {
                installed_app_id: appWs.overrideInstalledAppId,
            };
        }
        return req;
    },
    output: (res) => {
        return res;
    },
});
